html,
body,
#root {
  width: 100%;
  height: 100%;
}

.fa-icon {
  transition: color 250ms ease-out;
}

.icon-primary {
  color: #ea7934;
}

.icon-primary:hover {
  color: #333;
  cursor: pointer;
}
